#intro {
  padding-top: 2rem;
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 60%;
  
  h1 {
    position: relative;
    display: inline-block;
    
    img {
      position: absolute;
      bottom: 75%;
      left: 85%;
      width: 30vw;
      max-width: 18rem;
    }
  }

  h2 { font-size: 1.6rem; }
  
  p {
    width: 85%;
    margin: 0 auto;
    max-width: 960px;
    padding: 1.7em 1em;
    border: 1px dashed #DDD;
    border-width: 1px 0;
    margin-top: 2.5em;
  }
}

@keyframes arrow-throb {
  0% {
    transform: translateY(0);   
  }
  15% {
    transform: translateY(20px);
    border-color: #999 #fff;
  }
  45% {
    transform: translateY(0);
    border-color: #ddd #fff;
  }
}


.pointer-arrows {
  margin: 2.5em 0 0;
   
  span {
    display: block;
    width: 0;
    height: 0;
    margin: 1.2em auto;

    border-style: solid;
    border-width: 15px 15px 0;
    border-color: #ddd #fff;

    animation: arrow-throb 3s cubic-bezier(.7,0,.5,1) 0s infinite;
    
    &:nth-child(2){
      border-width: 11px 11px 0;
      animation-delay: .15s;
    }
    
    &:nth-child(3){
      border-width: 7px 7px 0;
      animation-delay: .3s;
    }
  }
}

#project-list {
  max-width: 1400px;
  padding: 0;
  margin: 0 auto;
  list-style: none;
  display: flex;
  flex-wrap: wrap;

  li {
    flex: 50% 0 0;
    padding: .5rem;
  }

  img {
    -webkit-user-drag: none;
    width: 100%;
    height: auto;
    display: block;
    border: 1px solid #ddd;
    border-radius: .4rem;
  }

  a {
    position: relative;
    display: block;
    text-decoration: none;
    user-select: none;
    
    div {
      background-color: RGBA(10,20,25,0);
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: column;
      top: 0;
      width: 100%;
      height: 100%;
      z-index: 1;
    }
    
    h3, p {
      color: #fff;
      opacity: 0;
      transition: transform 0.2s cubic-bezier(0,0.7,0.3,1), opacity 0.2s cubic-bezier(0.5,0.1,0.4,0.95);
      transition-delay: 0.22s, 0s;
      transform: translateY(-20px) scale(2);
    }
    
    h3 {
      font-size: clamp(1rem,5vw,2.1rem);
      margin: 0;
      line-height: 1.3;
      margin-top: -0.3em;
    }
    
    p {
      font-size: clamp(0.9rem, 1.4vw, 1.2rem);
      margin: 0;
    }

    img {
      transition: filter .2s linear;
    }
    
    &:hover,
    &:focus {
      img {
        filter:  brightness(0.2);
      }

      h3, p {
        transform: translateY(0) scale(1);
        opacity: 1;
      }
      
      h3 {
        transition-delay: 0s, 0s;
      }
      
      p {
        transition-delay: 0.12s, 0.12s;
      }
    }
    
    &:active {
      top: 0;
    }
  }
}

@media (max-width: 700px) {
  #intro {
    padding: 2em 0 1em;
    
    h1 svg {
      display: block;
      position: static;
      width: 65vw;
      left: 0;
      margin: 0 auto;  
    }

    h1 img {
      position: static;
      width: 75%;
      display: block;
      margin: 0 auto;
    }
  }
}

@media (max-width: 800px) {
  #project-list li {
    flex: 100% 0 0;
  }
}
