:root {
  --title-font: 'Open Sans', sans-serif;
  --body-font: 'Roboto Slab', 'serif';
  --color-primary: #0085e3;
}

* {
  box-sizing: border-box;
}

.preload * {
  transition: none !important;
}

html, body, main {
  width: 100%;
  height: 100%;
}

html {
  color: #6f6f6f;
  font-size: 17px;
  font-family: var(--body-font);
  font-weight: 350;
}

body {
  line-height: 1.6;
}

main {
  position: relative;
  perspective: 1200px;
  overflow: hidden;
}

.page {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: min(20vw,6rem) 1.5rem 3rem;
  visibility: hidden;
  opacity: 0;
  overflow: hidden;
  background: #fff;
  overflow-y: scroll;
  transform: scale(1);
  transition: opacity 0.2s cubic-bezier(.2,.5,.45,1) 0s, transform 0.25s cubic-bezier(.2,.5,.45,1) 0s, visibility 0s linear 0.3s;
}

[data-page=home] #home-page,
[data-page=project] #project-page {
  visibility: visible;
  opacity: 1;
  pointer-events: all;
  transition-delay: 0s, 0s, 0s;
}

[data-page=home] #project-page {
  transform: scale(.8);
}

[data-page=project] #home-page {
  transform: scale(1.2);
}

#project-page > section {
  display: none;
}

#project-page > section.active {
  display: block;
}


h1, h2, h3, h4, h5, h6 {
  font-weight: 300;
  margin: .6rem 0;
  line-height: 1.1;
  font-family: var(--title-font);
}

h1 {
  font-size: clamp(3rem, 6vw, 5rem);
}

h2 {
  font-size: clamp(2rem, 2.8rem, 2.8rem);
}

h3 {
  font-size: 1.5rem;   
}

h4 {
  font-size: 1.4rem;   
}

h5 {
  font-size: 1.2rem;   
}

h1, h2, h3, h4, h5 {
  + p {
    margin-top: 1.7em;
  }
}

a {
  -webkit-font-smoothing: antialiased;
  position: relative;
  cursor: pointer;
  color: var(--color-primary);
  
  &:hover,
  &:focus {
    text-decoration: none;
  }
  
  &:active {
    top: 1px;
  }
}

.external-link:after {
  content: '';
  display: inline-block;
  margin-left: 0.3em;
  margin-right: 0.1em;
  width: 0.8em;
  height: 0.8em;
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#0085e3' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-external-link'%3E%3Cpath d='M18 13v6a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2V8a2 2 0 0 1 2-2h6'%3E%3C/path%3E%3Cpolyline points='15 3 21 3 21 9'%3E%3C/polyline%3E%3Cline x1='10' y1='14' x2='21' y2='3'%3E%3C/line%3E%3C/svg%3E");
  background-size: cover;
}

::-moz-selection {
  color: #fff;
  background: #de4d4d;
}

::selection {
  color: #fff;
  background: #de4d4d;
}

b, strong {
  color: #111;
  font-weight: inherit;

  p & {
    font-weight: 500;
  }
}

dt {
  font-size: 1.1rem;
  color: #000;
}

dd {
  margin: 0.3em 0 1.2em;   
}

// Tag list
.tags {
  list-style: none;
  padding: 0;
  margin: 0;
  font-size: .9em;
  
  li {
    display: inline-block;
    margin: 0 .1em .3em 0;
    padding: .2em 1em;
    background: #303030;
    color: #FFF;
    border-radius: 2em;
    font-size: 0.8rem;
  }
}

.title-hover {
  position: relative;
  
  &:after {
    content: attr(data-title);
    background: rgba(0, 0, 0, .8);
    color: #FFF;
    padding: 0.3em 1em 0.5em;
    visibility: hidden;
    opacity: 0;
    font-size: .8rem;
    text-align: center;
    line-height: 1.6;
    white-space: nowrap;
    position: absolute;
    top: 120%;
    left: 0;
    transition: opacity 0.2s 0s, visibility 0s 0.2s;
  }
  
  &.title-hover-right:after {
    right: 0;
    left: inherit;
  }
  
  &:hover,
  &:focus {
    &:after {
      opacity: 1;
      visibility: visible;
      -webkit-transition-delay: 0s,0s;
      transition-delay: 0s,0s;
    }
  }
}

.scale-btn {
  img,
  svg {
     -webkit-transition: -webkit-transform 0.15s cubic-bezier(0,0.8,0.4,1);
    transition: transform 0.15s cubic-bezier(0,0.8,0.4,1);
  }
  
  &:hover,
  &:focus {
    img,
    svg {
      -webkit-transform: scale(1.05);
      transform: scale(1.05);
    }
  }
  
  &:active {
    img,
    svg {
      -webkit-transition: none;
      transition: none; 
      
      -webkit-transform: scale(0.95);
      transform: scale(0.95);
    }
  }
}

object[data-swfid] {
  display: none;
}
.swf-trigger {
  cursor: pointer;
}


/* Headers, footers and container styling */

#page-header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: space-between;
  background: RGBA(245,245,245,0.9);
  padding: .5rem 0;
  font-size: 110%;
  z-index: 100;
  color: #878787;
  
  img {
    display: block;
  }
  
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
  }
}

#page-nav {
  display: inline-block;
  transition: transform 0.25s ease-in-out;

  &.back-active {
    transform: translateX(-55px);

    #page-logo {
      opacity: 0;
      pointer-events: none;

      img {
        transform: rotate(-150deg);
      }
    }

    #page-back-btn {
      opacity: 1;
      pointer-events: all;
    }
  }
}

#page-logo,
#page-back-btn {
  display: inline-block;
  vertical-align: middle;
  transition: opacity 0.2s linear;
}

#page-back-btn {
  padding: .3em;
  opacity: 0;
  pointer-events: none;
}
#page-back-btn svg {
  display: block;

}

#page-logo {
  margin-left: .7em;
  position: relative;
  -webkit-user-drag: none;
  -webkit-user-select: none;
  user-select: none;
  
  img {
    transition: transform 0.5s ease;
  }
}

#page-breadcrumb {
  visibility: hidden;
  opacity: 0;
  margin: -.1em 0 0;
  
  transition: opacity 0.2s linear 0s, visibility 0.2s linear 0.2s;
  
  &.active {
    visibility: visible;
    opacity: 1;
    transition-delay: 0s, 0s;
  }
}

#social-links {
  display: flex;
  margin: 0 0.7rem 0 0;

  a {
    margin-left: .2rem;
    transition: filter 0.1s linear;

    &:hover,
    &:focus {
      filter: brightness(1.2);
    }
  }
}
