#project-page {
  > section {
    max-width: 1000px;
    margin: 0 auto;
  }
  
  header {
    padding-right: 3em;
  }
  
  h2 {
    text-indent: -0.05em;
  }
  
  header {
    flex-grow: 1;
  }
  
  aside {
    margin-top: 1.5em;
    padding: 0 2% 1em 3%;
    border-left: 1px dashed #ddd;
    flex-basis: 18rem;
    flex-shrink: 0;
  }
  
  figure {
    position: relative;
    margin: 0 0 5vw;
    clear: both;
    background: #f6f6f6;
    box-shadow: 0 19px 38px rgba(0,0,0,0.08), 0 15px 12px rgba(0,0,0,0.04);
  }
  
  img {
    width: 100%;
    min-height: 8rem;
    display: block;
    background: url(../img/rainbowlemon-loading.gif) no-repeat 50% 50%;
    
    &.show {
      transition: opacity .6s linear;
      opacity: 1 !important;
    }
  }
}

.project-header {
  display: flex;
  align-items: center;
  margin-bottom: 2.5rem;
}

@media screen and (max-width: 600px) {
  .project-header {
    display: block;
  }
}